<template>
  <b-form
    autocomplete="off"
    @submit.prevent="Search"
  >
    <b-row class="mb-2">
      <!-- <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <v-select
            v-model="form.polymer_type"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :reduce="op => op.id"
            :placeholder="t('Polymer Type')"
            :options="AllPolymerTypeList"
          />
        </b-form-group>
      </b-col> -->
      <b-col cols="6" md="3">
        <b-form-group
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-title"
              v-model="form.title"
              type="text"
              autocomplete="off"
              :placeholder="t('Polymer Type')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <v-select
            v-model="form.period"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :reduce="op => op.value"
            :placeholder="t('Period')"
            :options="periodOptions[$i18n.locale]"
            @input="onChangePeriod"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <b-input-group class="input-group-merge">
            <flat-pickr
              v-model="form.start_date"
              :placeholder="t('Select Start Date')"
              class="form-control"
              :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
              :config="{ dateFormat: 'Y-m-d'}"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <b-input-group class="input-group-merge">
            <flat-pickr
              v-model="form.end_date"
              class="form-control"
              :placeholder="t('Select End Date')"
              :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
              :config="{ dateFormat: 'Y-m-d'}"
            />
          </b-input-group>
        </b-form-group>
      </b-col> -->
      <b-col
        cols="12"
        class="text-center mt-1"
      >
        <b-button
          variant="success mx-1"
          type="submit"
        >
          {{ t('Search') }}
        </b-button>
        <b-button
          variant="danger mx-1"
          @click="resetFilter"
        >
          {{ t('Reset') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
// import vSelect from 'vue-select'
import {
  BRow, BCol, BInputGroup, BFormGroup, BButton, BForm,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
// import moment from 'moment'
import { useUtils } from '@core/libs/i18n'
// import store from '@/store'
// import { periodOptions, getDateByPeriod } from '@/utils/common'
import { periodOptions } from '@/utils/common'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    // vSelect,
    // flatPickr,
    BInputGroup,
    BFormGroup,
    BButton,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  props: ['filterparams'],
  data() {
    return {
      periodOptions,
      isDateDisable: true,
      AllPolymerTypeList: [],
      form: {
        title: '',
        // start_date: null,
        // end_date: null,
        // period: '',
      },
    }
  },
  watch: {
    filterparams(val) {
      this.form = val
    },
  },
  mounted() {
    // await store.dispatch('SettingStore/getAllPolymerType')
    //   .then(response => {
    //     if (response.data.code === '200') {
    //       this.AllPolymerTypeList = response.data.data
    //     }
    //   })
    // this.form.start_date = moment().startOf('month').format('YYYY-MM-DD')
    // this.form.end_date = moment().endOf('month').format('YYYY-MM-DD')
    // this.form.period = '4'
    this.Search()
  },
  methods: {
    // onChangePeriod(val) {
    //   const dates = getDateByPeriod(val)
    //   this.form.start_date = dates['0']
    //   this.form.end_date = dates['1']
    //   this.isDateDisable = dates['2']
    // },
    Search() {
      this.$emit('searchData', this.form)
    },
    resetFilter() {
      this.form = {
        title: '',
        // start_date: null,
        // end_date: null,
        // period: '',
      }
      // this.form.period = '4'
      // this.onChangePeriod(this.form.period)
      this.Search()
    },

  },

}
</script>

<template>
  <b-modal
    id="CreatePolymerTypeModal"
    :title="modalTitle"
    size="lg"
    hide-footer
  >
    <b-card>
      <validation-observer
        ref="FormValidation"
      >
        <b-form
          enctype="multipart/form-data"
          autocomplete="off"
          @submit.prevent="checkValidation"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="t('Status')"
                label-for="vi-status"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Status"
                  vid="vi-status"
                >
                  <v-select
                    v-model="form.status"
                    class="p-0 border-0 w-100"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :placeholder="t('Select Status')"
                    :reduce="s => s.id"
                    :options="statusList"
                  />
                </validation-provider>
                <!-- <validation-provider
                  rules="required"
                  #default="{ errors }"
                  class="input-group"
                  name="Status"
                  vid="vi-status"
                >
                  <v-select
                    v-model="form.status"
                    class="p-0 border-0 w-100"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :placeholder="t('Select Status')"
                    :reduce="s => s.id"
                    :options="statusList"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider> -->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="t('Polymer Type Name')"
                label-for="vi-title"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Polymer Type"
                  vid="vi-title"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-title"
                      v-model="form.title"
                      type="text"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Enter Polymer Type Name')"
                    />
                  </b-input-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="text-center"
            >
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
              >
                {{ t('Submit') }}
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="modalCancel"
              >
                {{ t('Cancel') }}
              </b-button>
            </b-col>
          </b-row></b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import constants from '@/constants'
import { hideLoader, showLoader } from '@/utils/common'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: ['polymerTypeData'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      openModal: false,
      modalTitle: '',
      modalData: [],
      statusList: [],
      form: {
        id: null,
        title: null,
        status: 1,
      },
    }
  },
  watch: {
    polymerTypeData: {
      handler(val) {
        this.modalTitle = val.name
        this.modalData = val
        this.openModal = true
        this.form.id = this.modalData.id
        this.form.status = this.modalData.status
        this.form.title = this.modalData.title
      },
    },
  },
  mounted() {
    this.statusList = constants.StatusList
    this.$root.$on('bv::modal::hidden', (a, componentId) => {
      if (componentId === 'CreatePolymerTypeModal') {
        this.openModal = false
        this.form = {
          title: null,
          status: 1,
        }
      }
    })
  },
  methods: {
    async onSubmit() {
      showLoader()
      await store.dispatch('SettingStore/CreatePolymerType', this.form).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.$bvModal.hide('CreatePolymerTypeModal')
          // this.$root.$emit('refreshLeadData')
          hideLoader()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: '',
            },
          })
          this.$root.$emit('refreshPolymerTypeList')
          this.$refs.FormValidation.reset()
        } else {
          hideLoader()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: e.msg,
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    modalCancel() {
      this.$bvModal.hide('CreatePolymerTypeModal')
    },
    checkValidation() {
      this.$refs.FormValidation.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
  },
}
</script>
